import React, { PureComponent } from "react";
import { Link } from "react-router-dom";


class Footer extends PureComponent {
  state = {};
  render() {
    return (
      <footer className="footer_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="ftr_logo">
                <img src="/images/logo.svg" alt="Logo" className="img-fluid" />
                <p>
                Effortless, Brisk, and Private. Transfer your currencies with our secure network today.{" "}
                </p>

                <ul>
                  <li>
                    <Link to="/terms-of-service">Terms of Service</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-3">
                  <div className="ftr_links">
                    <h6>EXPLORE</h6>
                    <ul>
                      <li>
                        <Link to="/?features">Features</Link>
                      </li>
                      <li>
                        <Link to="/faqs">FAQs</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-3">
                  <div className="ftr_links">
                    <h6>LEGAL</h6>
                    <ul>
                      <li>
                        <Link to="/terms-of-service">Terms</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-6">
                  <div className="ftr_social">
                    <h6>SOCIAL</h6>
                    <ul>
                      <li>
                      <a href="https://discord.gg/Whhpfx73BB" target="_blank">                          
                      <i class="icon fa-brands fa-discord"></i>                        

                        </a>
                      </li>
                      <li>
                      <a href="https://twitter.com/coinchange_to" target="_blank">                          
                          <i class="icon fa-brands fa-twitter"></i>                        
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ftr_copy_txt">
                <p>Copyright &copy; 2022 Coin Change. All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
