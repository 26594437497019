const Cookies = require("js-cookie");

function LineChart(dom, data) {
  var Chart = window.Chart;
  new Chart(dom, {
    type: "line",
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                return value.toFixed(2) + "$";
              },
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
    data,
  });
  return Chart;
}

module.exports = {
  LineChart,

  // check if the user is logged in
  checkAccess: (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        Cookies.remove("footprint");
        window.location.href = "/";
      }
    }
    return true;
  },

  // get mins and secs from seconds minus from 30 mins
  getMinsSecs: (seconds) => {
    // seconds = 30 * 60 - Math.abs(seconds);
    var mins = Math.floor(seconds / 60);
    var secs = seconds % 60;
    return `${mins} mins ${secs} secs`;
  },

  // get date time from timestamp
  getDateTime: (timestamp) => {
    var date = new Date(timestamp * 1000);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();
    var time =
      day + "/" + month + "/" + year + " " + hour + ":" + min + ":" + sec;
    return time;
  },

  // get string and replace space with - and lowercase
  getCoinName: (str) => {
    return str.replace(/\s/g, "-").toLowerCase();
  },

  // get different between two dates in seconds and extend 10 min in first
  getDifference: (start) => {
    // add 10 minds in end datetime
    start = new Date(start);
    start.setMinutes(start.getMinutes() + 10);
    var difference = start - new Date();
    return parseInt(difference / 1000);
  },

  // get ordered string array
  getOrderedStringArray: (array) => {
    return array.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
  },

  // get block chain link status
  getBlockChainLinkStatus: (currency) => {
    var currencies = [
      "bitcoin",
      "ethereum",
      "ripple",
      "litecoin",
      "doge",
      "monero",
      "cardano",
      "stellar",
    ];
    if (currencies.includes(currency.toLowerCase())) {
      return true;
    }
    return false;
  },
};
