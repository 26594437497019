const Cookies = require("js-cookie");
var protocol = window.location.protocol;

module.exports = {
  server: "https://api.coinchange.to",
  // server: protocol.includes("https")
  //   ? "https://api.coinchange.to"
  //   : "http://localhost:8000",

  config: {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  },

  // recaptcha keys
  RECAPTCHA_KEY: "6LffrH0dAAAAAB9xl_D6BlLrjbHSTJhfcynxSXKA",
};
