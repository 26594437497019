import React, { PureComponent } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";

import Home from "./source/Home";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import About from "./source/About";
import FAQ from "./source/Faq";
import ForgotPassword from "./auth/Forgot";
import Orders from "./source/Orders";
import Order from "./source/Order";
import Privacy from "./source/Privacy";
import TermsOfService from "./source/Terms";
import ResetPassword from "./auth/Reset";

class App extends PureComponent {
  state = {};
  render() {
    return (
      <Router>
        <Header />
        <Routes>
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/login" element={<Login />} />

          <Route path="/order/:id" element={<Order />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    );
  }
}

export default App;
