import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { server, config } from "../env";

class Header extends PureComponent {
  state = {
    isLoggedIn: false,
  };

  componentDidMount = async () => {
    await axios(server + "/v1/auth/access", config)
      .then((rsp) => {
        this.setState({
          isLoggedIn: true,
        });
      })
      .catch((err) => {
        this.setState({
          isLoggedIn: false,
        });
      });
  };


  // logout
  logout = () => {
    Cookies.remove("token");
    window.location.href = "/";
  };

  render() {
    const { isLoggedIn } = this.state;
    return (
      <header className="header_section">
        <div className="container">
          <nav className="navbar px-0 navbar-expand-lg navbar-light">
            <Link className="navbar-brand" to="/">
              <img src="/images/logo.svg" alt="logo" className="img-fluid" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav m-auto custm_scrl">
                <li className="nav-item">
                  <Link className="nav-link" onClick={() => {
                    if(window.location.pathname == "/") document.getElementById("feat_sec").scrollIntoView();
                  }} to="/?features">
                    Features
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/orders">
                    My Orders
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about-us">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/faqs">
                    FAQs
                  </Link>
                </li>
              </ul>
              <form className="form-inline">
                <a href="https://discord.gg/Whhpfx73BB" target="_blank">
                <i class="icon fa-brands fa-discord"></i>                        

                </a>
                <a href="https://twitter.com/coinchange_to" target="_blank">
                <i class="icon fa-brands fa-twitter"></i>                        

                </a>
                {!isLoggedIn ? (
                  <Fragment>
                    <Link to="/login">Log In</Link>
                    <Link to="/sign-up">Sign up</Link>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Link to="#" onClick={this.logout}>
                      Logout
                    </Link>
                  </Fragment>
                )}
              </form>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
