import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import PageLoader from "../components/PageLoader";
import axios from "axios";
import { server, config } from "../env";
import { checkAccess } from "../helper/helper";

class Orders extends PureComponent {
  state = {
    total_transactions: 0,
    transactions: [],
    url: "/v1/order/read",
    next: null,
    show_more_button: "All are caught up!",
    isLoaded: false,
    isLogin: false,
  };

  componentDidMount = async () => {
    await axios(server + "/v1/auth/access", config)
      .then((rsp) => {
        this.setState({
          isLoaded: true,
          isLogin: true,
        });
      })
      .catch((err) => {
        window.location.href = "/login";
        this.setState({
          isLogin: false,
        });
      });
    this.readTransactions(this.state.url);
  };

  readTransactions = async (url, load_more = null, search = null) => {
    // Load more
    url = load_more !== null ? server + load_more : server + url;
    // Search and filter
    url = search !== null ? server + search : url;

    this.setState({
      show_more_button: <Loader />,
    });

    await axios
      .get(url, config)
      .then((rsp) => {
        this.setState({
          total_transactions: rsp.data.count,
          transactions:
            load_more !== null
              ? this.state.transactions.concat(rsp.data.results)
              : rsp.data.results,
          next:
            rsp.data.next == null
              ? null
              : rsp.data.next.replace(/^.*\/\/[^/]+/, ""),
          show_more_button:
            rsp.data.next == null ? "All are caught up!" : "Show more",
        });
      })
      .catch((err) => {
        checkAccess(err);
      });
  };

  show_more = (url) => {
    this.readUsers(null, url);
  };

  // Search users by username and email
  search = async (e) => {
    this.readTransactions(
      null,
      null,
      this.state.url + "?search=" + e.target.value
    );
  };

  render() {
    const { transactions, total_transactions, show_more_button, next } =
      this.state;
    const { isLoaded } = this.state;

    return (
      <section className="my_order_sec">
        {isLoaded ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="common_heading">
                  <h2>
                    My <span>Orders</span>
                  </h2>
                  <p>
                    In hac habitasse platea dictumst. Proin sollicitudin odio
                    augue. Cras nibh m.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="order_search_wrap">
                  <input
                    type="text"
                    placeholder="Search by Currency / Username / Email Address"
                    onChange={this.search}
                  />
                  <span>Total Transactions : {total_transactions}</span>
                </div>
                <div className="order_main_table">
                  <table>
                    <tr>
                      <th>#</th>
                      <th>From Currency</th>
                      <th>To Currency</th>
                      <th>From Qty.</th>
                      <th>To Qty.</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                    {transactions.map((transaction, index) => (
                      <tr>
                        <td>#{index + 1}</td>
                        <td>{transaction.fromCurrency}</td>
                        <td>{transaction.toCurrency}</td>
                        <td>{transaction.fromQty}</td>
                        <td>{transaction.toQty}</td>
                        <td>
                          {transaction.status === "completed" ? (
                            <span className="badge bg-success">Completed</span>
                          ) : (
                            <span className="badge bg-warning">Pending</span>
                          )}
                        </td>
                        <td>
                          <a
                            href={`https://coinchange.to/order/${transaction.id}`}
                            className="btn btn-sm btn-primary"
                            target={`_blank`}
                          >
                            View
                          </a>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
            {/* Show more button */}
            <div className="text-center mt-3">
              <Link
                to="#"
                className="btn btn-primary rounded-pill px-3"
                style={{
                  backgroundColor: "#485EED",
                  border: "unset",
                }}
                onClick={next == null ? null : () => this.show_more(next)}
              >
                {show_more_button}
              </Link>
            </div>
          </div>
        ) : (
          <PageLoader className="text-white" />
        )}
      </section>
    );
  }
}

export default Orders;
