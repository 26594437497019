import React, { PureComponent } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { server, RECAPTCHA_KEY } from "../env";
import { Link } from "react-router-dom";

// helper components
import Loader from "../components/Loader";
import Recaptcha from "react-google-invisible-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Signup extends PureComponent {
  state = {
    showPassword: false,
    loader: "",
    message: "",
    reCaptcha: null,
  };
  notify = (message, type) => {
    toast(message, { type, theme: "colored" });
  };
  handlePasswordVisiblity = () => {
    this.setState({
      showPassword: this.state.showPassword ? false : true,
    });
  };

  signup = async (e) => {
    e.preventDefault();

    this.setState({
      loader: <Loader />,
    });

    await this.recaptcha.execute();

    const params = {
      username: e.target.username.value,
      email: e.target.email.value,
      password: e.target.password.value,
      "g-recaptcha-response": this.state.reCaptcha,
    };

    await axios
      .post(server + "/v1/auth/signup", params)
      .then((rsp) => {
        Cookies.set("token", rsp.data.payload.token);
        this.setState({
          loader: "",
        });
        this.notify(rsp.data.detail, "success");

        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response) {
          this.notify(err.response.data.detail, "error");
        }
        this.setState({
          loader: "",
        });
      });
  };

  onResolved = () => {
    this.setState({ reCaptcha: this.recaptcha.getResponse() });
  };

  render() {
    const { showPassword, loader } = this.state;

    return (
      <section className="login_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="signup_inner_wrap">
                <div className="signup_frm_wrap">
                  <form onSubmit={this.signup}>
                    <h6>Sign Up</h6>
                    <p>
                      Hi, Please enter following details to <br />
                      create your account
                    </p>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        name="username"
                        autoFocus
                        required
                      />
                      <i className="fa fa-user"></i>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Address"
                        name="email"
                        required
                      />
                      <i className="fa fa-envelope"></i>
                    </div>

                    <div className="form-group">
                      <input
                        id="password-field2"
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        name="password"
                        placeholder="Confirm Password"
                        required
                      />
                      <span
                        toggle="#password-field2"
                        className="toggle-password"
                        onClick={this.handlePasswordVisiblity}
                        id="myDIV2"
                      >
                        View
                      </span>
                    </div>
                    <div className="form-group ">
                      <button type="submit" className="btn btn_submit">
                        Create Account {loader}
                      </button>
                    </div>
                    <div className="form-group mb-0">
                      <p>
                        Already have an account? <Link to="/login">Log In</Link>{" "}
                        <br />
                        Forget Password ?{" "}
                        <Link to="/forgot-password">Reset Password</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_KEY}
          onResolved={this.onResolved}
        />
      </section>
    );
  }
}

export default Signup;
