import React, { PureComponent } from "react";
import axios from "axios";
import PageLoader from "../components/PageLoader";
import { server, RECAPTCHA_KEY } from "../env";
import {
  getCoinName,
  getDateTime,
  getMinsSecs,
  getBlockChainLinkStatus,
} from "../helper/helper";
import Loader from "../components/Loader";
import Recaptcha from "react-google-invisible-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Order extends PureComponent {
  state = {
    orderId: {},
    data: {},
    seconds: parseInt(localStorage.getItem("timeLeft")),
    created: "",
    order: {},
    email: "",
    emailLoader: "",
    emailMessage: "",

    choice: "continue",
    formloader: "",
  };

  notify = (message, type) => {
    toast(message, { type, theme: "colored" });
  };

  componentDidMount = () => {
    const orderId = window.location.href.split("/")[4];
    this.setState({ orderId });

    this.getOrder(orderId);
    // read order in every 10 secs
    setInterval(() => {
      this.getOrder(orderId);
    }, 10000);
  };

  getOrder = async (id = this.state.orderId) => {
    axios
      .get(server + "/v1/order/read/" + id)
      .then((res) => {
        localStorage.setItem(
          "timeLeft",
          parseInt(res.data.payload.order.timeLeft)
        );

        this.setState({
          order: res.data.payload.order,
          data: res.data.payload.data,
          seconds: parseInt(res.data.payload.order.timeLeft),
          created: res.data.payload.order.created,
          email: res.data.payload.order.email,
        });
      })
      .catch((err) => {
        console.log(err);
        // window.location.href = "/";
      });

    if (!this.timer) {
      this.startTimer();
    }
  };

  // start timer for order increase 1 sec into state
  startTimer = () => {
    this.timer = setInterval(() => {
      localStorage.setItem("timeLeft", this.state.seconds - 1);
      this.setState({ seconds: this.state.seconds - 1 });
    }, 1000);
  };

  // add email to address
  addEmail = async (e) => {
    e.preventDefault();
    const email = this.state.email;

    this.setState({
      emailLoader: <Loader />,
    });

    await this.recaptcha.execute();

    const params = {
      email,
      "g-recaptcha-response": this.state.reCaptcha,
    };

    await axios
      .post(server + "/v1/order/add_email/" + this.state.orderId, params)
      .then((rsp) => {
        this.notify(rsp.data.detail, "success");
      })
      .catch((err) => {
        this.notify(err.response.data.detail, "error");
      });

    this.setState({
      emailLoader: "",
    });
  };

  onResolved = () => {
    this.setState({ reCaptcha: this.recaptcha.getResponse() });
  };

  copyAnimation = (e) => {
    // hide and show content withing 200ms
    e.target.style.opacity = 0;
    setTimeout(() => {
      e.target.style.opacity = 1;
    }, 200);
  };

  submitForm = async (e) => {
    e.preventDefault();

    this.setState({
      formloader: <Loader />,
      formMessage: "",
    });

    const params = {
      "g-recaptcha-response": this.state.reCaptcha,
      id: this.state.orderId,
      address: e.target.address ? e.target.address.value : "",
      choice: this.state.choice,
    };

    await axios
      .post(server + "/v1/order/emergency", params)
      .then((rsp) => {
        this.setState({
          formloader: "",
          choice: "continue",
        });
        this.notify(rsp.data.detail, "success");
      })
      .catch((err) => {
        this.notify(err.response.data.detail, "error");
        this.setState({
          formloader: "",
        });
      });
  };
  render() {
    const { data, seconds, order, email, emailLoader } = this.state;
    const { choice, formloader } = this.state;

    return (
      <section className="order_details_sec">
        <div className="container">
          {Object.keys(data).length > 0 ? (
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="order_details_head">
                  <div className="detials_ordr_lft">
                    <p>SEND</p>
                    <p>{data.from.currency}</p>
                    <p>{data.from.amount}</p>
                    <small className="text-white">{data.from.address}</small>
                  </div>
                  <div className="ordr_details_cent">
                    <img
                      src="/images/arrws_2.svg"
                      alt="a"
                      className="img-fluid"
                    />
                  </div>
                  <div className="detials_ordr_lft detials_ordr_rgt">
                    <p>RECIEVE</p>
                    <p>{data.from.currency}</p>
                    <p>{data.from.amount}</p>
                    <small className="text-white">{data.from.address}</small>
                  </div>
                </div>
                <div className="processing_step_wrap ">
                  <div className="process_step_box rgt_line_bar">
                    <span>
                      <img
                        src="/images/task_1.svg"
                        alt="a"
                        className="img-fluid"
                      />
                    </span>
                    <h6 className={data.from.tx.id ? "text-success" : ""}>
                      {data.from.tx.id ? "Completed" : "Pending"}
                    </h6>
                    <p>
                      Awaiting <br />
                      Deposit
                    </p>
                  </div>
                  <div className="process_step_box rgt_line_bar_2">
                    <span>
                      <img
                        src="/images/click.svg"
                        alt="a"
                        className="img-fluid"
                      />
                    </span>
                    <h6
                      className={
                        data.from.tx.confirmations > 2 ? "text-success" : ""
                      }
                    >
                      {data.from.tx.confirmations > 2 ? "Complete" : "Pending"}
                    </h6>
                    <p>
                      Awaiting
                      <br />
                      Confirmation
                    </p>
                  </div>
                  <div className="process_step_box rgt_line_bar_2">
                    <span>
                      <img
                        src="/images/transfer_ic.svg"
                        alt="a"
                        className="img-fluid"
                      />
                    </span>
                    <h6 className={data.to.tx.id ? "text-success" : ""}>
                      {data.to.tx.id ? "Complete" : "Pending"}
                    </h6>
                    <p>
                      Performing <br />
                      Exchange
                    </p>
                  </div>
                  <div className="process_step_box">
                    <span>
                      <svg
                        width="50"
                        height="50"
                        viewBox="0 0 93 93"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="46.5679"
                          cy="46.5467"
                          r="45.8817"
                          fill="#485EED"
                        />
                        <path
                          d="M36.3358 28.5625C43.0816 27.8086 50.0543 27.8086 56.8001 28.5625C59.3328 28.8456 61.5561 30.2152 62.9625 32.2043L45.4756 49.6912L40.0797 44.2954C39.4398 43.6555 38.4023 43.6555 37.7624 44.2954C37.1224 44.9353 37.1224 45.9728 37.7624 46.6128L44.3169 53.1673C44.9568 53.8072 45.9943 53.8072 46.6343 53.1673L64.3652 35.4364C64.4143 35.6757 64.4535 35.9188 64.4823 36.1652C65.289 43.0626 65.289 50.0304 64.4823 56.9278C64.0129 60.9413 60.7905 64.0845 56.8001 64.5305C50.0543 65.2844 43.0816 65.2844 36.3358 64.5305C32.3454 64.0845 29.123 60.9413 28.6536 56.9278C27.8469 50.0304 27.8469 43.0626 28.6536 36.1652C29.123 32.1517 32.3454 29.0085 36.3358 28.5625Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <h6
                      className={
                        data.step === "done" || data.status === "DONE"
                          ? "text-success"
                          : ""
                      }
                    >
                      {data.step === "done" || data.status === "DONE"
                        ? "Complete"
                        : "Pending"}
                    </h6>
                    <p>
                      Exchange <br />
                      Completed
                    </p>
                  </div>
                </div>

                <div className="ordr_desc_wrap">
                  <span>
                    <h6>Order ID</h6>

                    <p>
                      {order.id
                        .split("-")
                        .slice(0, order.id.split("-").length - 2)
                        .join("-")}
                      <br />-
                      {order.id
                        .split("-")
                        .slice(
                          order.id.split("-").length - 2,
                          order.id.split("-").length
                        )
                        .join("-")}{" "}
                    </p>
                  </span>
                  <span>
                    {data.from.tx.id ? (
                      <div className="card-body text-center border-bottom">
                        <h6 className="text-warning">Order Status</h6>
                        {data.status === "DONE" || data.step === "done" ? (
                          <h6 className="text-success">Done</h6>
                        ) : (
                          <p className="text-muted">User Response</p>
                        )}
                      </div>
                    ) : (
                      <div className="card-body text-center border-bottom">
                        <h6 className="text-warning">Time Remaining</h6>
                        <p>
                          {seconds > 0 ? (
                            getMinsSecs(seconds)
                          ) : (
                            <span className="text-danger">Expired</span>
                          )}
                        </p>
                      </div>
                    )}
                  </span>
                  <span>
                    <h6>Order Type</h6>

                    <p>{data.type.toUpperCase()}</p>
                  </span>
                  <span>
                    <h6>Creation Time &amp; Date</h6>

                    <p>{getDateTime(data.reg || data.time.reg)}</p>
                  </span>
                </div>

                <div className="row">
                  {data.status !== "DONE" &&
                  data.step !== "done" &&
                  data.step !== "expired" ? (
                    data.from.tx.id ? (
                      <div className="col-lg-8">
                        <div className="ordr_addrs_wrap">
                          <div className="d-flex">
                            <h4>Transaction Info</h4>
                            {getBlockChainLinkStatus(data.from.name) ? (
                              <a
                                href={`https://blockchair.com/${getCoinName(
                                  data.from.name
                                )}/transaction/${
                                  data.from.tx.id
                                }?from=coinchange`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                View on Blockchain{" "}
                                <img
                                  src="/images/b_icon.svg"
                                  alt="a"
                                  className="img-fluid"
                                />
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                          <small className="text-white">
                            <span className="text-white">Txn Id : </span>
                            {data.from.tx.id}{" "}
                          </small>

                          <div className="d-flex">
                            <p>
                              Recieved Time:{" "}
                              {getDateTime(
                                data.from.tx.timeReg || data.from.tx.time
                              )}
                            </p>
                            <p>
                              Block Time: {getDateTime(data.from.tx.timeBlock)}
                            </p>
                          </div>

                          <div className="d-flex">
                            <p>Confirmations: {data.from.tx.confirmations}</p>
                            <p>
                              Fee: {data.from.tx.fee} {data.from.tx.ccyfee}
                            </p>
                          </div>
                        </div>
                        <div className="ordr_addrs_eth">
                          <h4>
                            Recieving {data.to.currency} address:
                            <span>{data.to.address}</span>
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div className="col-lg-8">
                        <div className="ordr_addrs_wrap">
                          <h4>
                            Send <span>{data.from.amount}</span>{" "}
                            <img
                              src="/images/btc.png"
                              alt="a"
                              className="img-fluid"
                            />{" "}
                            {data.from.currency} to this address:{" "}
                          </h4>
                          <div
                            className="code_copy"
                            onClick={(e) => {
                              navigator.clipboard.writeText(data.from.address);
                              this.copyAnimation(e);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span>{data.from.address}</span>{" "}
                            <img
                              src="/images/copy_icon.svg"
                              alt="a"
                              className="img-fluid"
                            />
                          </div>

                          <p>
                            Note : The exchange rate will be fixed after
                            receiving 1 network confirmations
                          </p>
                        </div>
                        <div className="ordr_addrs_eth">
                          <h4>
                            Recieving {data.to.currency} address:
                            <span>{data.to.address}</span>{" "}
                          </h4>
                        </div>
                      </div>
                    )
                  ) : data.status === "5" ||
                    data.status === "7" ||
                    data.step === "expired" ? (
                    <div className="col-lg-8">
                      <form action="" onSubmit={this.submitForm}>
                        <div className="ordr_addrs_wrap coin_change_emrgncy">
                          <h4>CoinChange Order Emergency</h4>
                          <p>
                            If you are seeing this popup, the order is either
                            expired or you have sent after the time specified,
                            In some cases even below the required amount. You
                            can choose what to do if funds arrive/arrived at
                            this address within 24 hours. Pick one of the
                            following::
                          </p>

                          <h6>
                            <input
                              type="radio"
                              name="choice"
                              onClick={() =>
                                this.setState({
                                  choice: "continue",
                                })
                              }
                              checked={choice === "continue"}
                            />{" "}
                            Continue my exchange (at the current market rate)
                          </h6>
                          <h6>
                            <input
                              type="radio"
                              name="choice"
                              onClick={() =>
                                this.setState({ choice: "refund" })
                              }
                              checked={choice === "refund"}
                            />
                            Make a refund of the amount sent for exchange minus
                            the network fee
                          </h6>

                          {choice === "refund" ? (
                            <div className="form-group text-white mt-3">
                              <label htmlFor="">
                                Your {data.from.name} Address
                              </label>
                              <input
                                type="text"
                                className="form-control bg-transparent text-white"
                                name="address"
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          <button className="btn btn_cnge" type="submit">
                            Continue to Exchange {formloader}
                          </button>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div className="col-lg-8">
                      <div className="ordr_addrs_wrap">
                        <div className="d-flex">
                          <h4>Accept Transaction Info</h4>
                          {getBlockChainLinkStatus(data.from.name) ? (
                            <a
                              href={`https://blockchair.com/${getCoinName(
                                data.from.name
                              )}/transaction/${
                                data.from.tx.id
                              }?from=coinchange`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View on Blockchain{" "}
                              <img
                                src="/images/b_icon.svg"
                                alt="a"
                                className="img-fluid"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                        <small className="text-white">
                          <span className="text-white">Txn Id : </span>
                          {data.from.tx.id}{" "}
                        </small>

                        <div className="d-flex">
                          <p>
                            Recieved Time:{" "}
                            {getDateTime(
                              data.from.tx.timeReg || data.from.tx.time
                            )}
                          </p>
                          <p>
                            Block Time: {getDateTime(data.from.tx.timeBlock)}
                          </p>
                        </div>

                        <div className="d-flex">
                          <p>Confirmations: {data.from.tx.confirmations}</p>
                          <p>
                            Fee: {data.from.tx.fee} {data.from.tx.ccyfee}
                          </p>
                        </div>
                      </div>
                      <div className="ordr_addrs_wrap">
                        <div className="d-flex">
                          <h4>Sent Transaction Info</h4>
                          {getBlockChainLinkStatus(data.to.name) ? (
                            <a
                              href={`https://blockchair.com/${getCoinName(
                                data.to.name
                              )}/transaction/${data.to.tx.id}?from=coinchange`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View on Blockchain{" "}
                              <img
                                src="/images/b_icon.svg"
                                alt="a"
                                className="img-fluid"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                        <small className="text-white">
                          <span className="text-white">Txn Id : </span>
                          {data.to.tx.id}{" "}
                        </small>

                        <div className="d-flex">
                          <p>
                            Recieved Time:{" "}
                            {getDateTime(data.to.tx.timeReg || data.to.tx.time)}
                          </p>
                          <p>Block Time: {getDateTime(data.to.tx.timeBlock)}</p>
                        </div>
                        <div className="d-flex">
                          <p>Confirmations: {data.to.tx.confirmations}</p>
                          <p>
                            Fee: {data.to.tx.fee} {data.to.tx.ccyfee}
                          </p>
                        </div>
                      </div>
                      <div className="ordr_addrs_eth">
                        <h4>
                          Recieving{" "}
                          <img
                            src="/images/eth.png"
                            alt="a"
                            className="img-fluid"
                          />{" "}
                          ETH address:
                          <small className="text-white">
                            {data.to.address}
                          </small>
                        </h4>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-4">
                    <div className="ordr_status">
                      <h3>Order status notifications </h3>

                      <p>
                        Enter your email if you want to receive notifications
                        about changes in the status of this order
                      </p>

                      <form onSubmit={this.addEmail}>
                        <input
                          type="email"
                          placeholder="Enter Email Address"
                          className="form-control text-white"
                          value={email}
                          onChange={(e) =>
                            this.setState({
                              email: e.target.value,
                            })
                          }
                        />
                        <button type="submit" className="btn btn_sub">
                          Submit {emailLoader}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="ordr_details_btm">
                  <h5>What do you need to know?</h5>

                  <ul>
                    <li>
                      <p>
                        {" "}
                        <i className="fa fa-circle"></i> You only need 1
                        confirmation of the Bitcoin blockchain for the exchange.
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        <i className="fa fa-circle"></i> Bitcoin transaction
                        confirmation speed depends on the level of blockchain
                        network congestion,
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        <i className="fa fa-circle"></i>We use segwit Bitcoin
                        addresses for faster and cheaper transactions, if your
                        wallet does not support this type of address, contact
                        technical support to change the address.
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        <i className="fa fa-circle"></i>We do not support
                        sending and receiving Ethereum using smart contracts.
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        <i className="fa fa-circle"></i>If the amount of the
                        transaction you sent differs from the initial amount
                        specified in the order with a float rate, the order will
                        be automatically recalculated.
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        <i className="fa fa-circle"></i> If your transaction is
                        received after the expiration of the order, but within
                        24 hours, then this transaction will be automatically
                        displayed in the order. You will be able to continue the
                        order yourself or make a refund.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <PageLoader className="text-white" />
          )}
        </div>

        <ToastContainer />
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_KEY}
          onResolved={this.onResolved}
        />
      </section>
    );
  }
}

export default Order;
